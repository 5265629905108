<template>
  <div class="lottery">
    <div class="backBtn" @click="backPage">
      <svg-icon class="navArrow" iconClass="whiteBack" />
    </div>
    <div class="mianBox" v-if="url">
      <iframe class="iframe" :src="url" frameborder="0"></iframe>
    </div>
    <Loading v-else />
  </div>
</template>

<script>
import Loading from "@/components/Loading";
import { getLocalItem } from "@/utils/longStorage";
import { getLotteryUrl } from "@/utils/getConfig";
export default {
  components: {
    Loading,
  },
  data() {
    return {
      url: "",
      isRequest: false,
    };
  },
  created() {
    let token = getLocalItem("token");
    let baseUrl = this.$store.getters.baseUrl;
    let apiUrl = baseUrl.replace("/api/app/", "");
    let lotteryUrl = getLotteryUrl();
    this.url = `${lotteryUrl}&token=${token}&appUrl=${apiUrl}&timestamp=${new Date().getTime()}`;
  },
  methods: {
    // 返回
    backPage() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
.lottery {
  height: 100%;
  width: 100%;
  .backBtn {
    position: fixed;
    top: 16px;
    left: 16px;
    z-index: 2;
  }

  .navBar {
    height: 44px;
    padding: 0 12px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e6e6e6;

    .navArrow {
      width: 30px;
      height: 30px;
    }

    .navTitle {
      flex: 2;
      text-align: center;
      font-size: 18px;
      color: #000;
    }

    .rightBtn {
      width: 17px;
      height: 17px;
    }
  }

  .mianBox {
    width: 100%;
    height: 100vh;

    .iframe {
      width: 100%;
      height: 100%;
    }
  }
}
.navArrow {
  width: 20px;
  height: 20px;
}
</style>
